import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, switchMap, tap, throwError } from 'rxjs';

import { UserStore } from '@core/stores';
import { AuthService } from '@core/modules/auth/services/auth.service';

type TErrorGQLResponse = { message: string; status: string };

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const userStore = inject(UserStore);
  const authService = inject(AuthService);

  const token = userStore.token();

  if (!token) {
    return next(req);
  }

  const clonedRequest = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(clonedRequest).pipe(
    tap((resp: HttpResponse<{ errors: TErrorGQLResponse[] }>) => {
      const isUnAuth = resp?.body?.errors?.some(
        (error) =>
          error?.status === 'UNAUTHENTICATED' &&
          error?.message === 'Expired token',
      );

      if (isUnAuth && userStore.token()) {
        throw new Error('EXPIRED');
      }
    }),
    catchError((err) => {
      if (err.message === 'EXPIRED') {
        return authService.refreshToken(token).pipe(
          tap((newToken) => {
            userStore.onSetToken(newToken);
          }),
          switchMap((newToken) =>
            next(
              req.clone({
                setHeaders: {
                  Authorization: `Bearer ${newToken}`,
                },
              }),
            ),
          ),
        );
      }

      return throwError(() => new Error(err.message));
    }),
  );
};
